<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card title="Manage Requests">
          <b-tabs
            content-class="mt-2"
            justified
            pills
          >
            <b-tab
              title="Startup Requests"
              active
            >
              <startup-requests />
            </b-tab>
            <b-tab title="Mentor Requests">
              <mentor-requests />
            </b-tab>
            <b-tab title="Partner Requests">
              <partner-requests />
              <!-- <emeetings/> -->
            </b-tab>
          </b-tabs>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BRow, BTab, BTabs, BOverlay, BCard,
} from 'bootstrap-vue'

import StartupRequests from '@/views/incubator-portal/startups/pages/startup-manager/requests/Startup.vue'
import MentorRequests from '@/views/incubator-portal/startups/pages/startup-manager/requests/Mentor.vue'
import PartnerRequests from '@/views/incubator-portal/startups/pages/startup-manager/requests/Partner.vue'

export default {
  components: {

    StartupRequests,
    MentorRequests,
    PartnerRequests,
    BRow,
    BCol,
    BTabs,
    BTab,
    BOverlay,
    BCard,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
